export class Building {
  Id: number;
  NameCompany: string;
  NameBuilding: string;
  TimeBetweenPages: number;
  Song: string;
  EveryMillisecondGoToServerInLobby: number;
  EveryMillisecondGoToServerInFloor: number;
  UrlScreenFloor: string;
  UrlScreenLobby: string;
  UrlBaseLogoCompany: string;

  Phone: any;
  Email: any;
  Address: any;
  Comment: any;
  Deleted: any;

  TimeBetweenRefreshDisplay: number;
  TextSizeFloorNameInLobby: number;
  TextSizeTimeInLobby: number;
  HeightLogoSivInLobby: number;
  HeightSivMainInLobby: number;
  LogoBuilding: string;
  LogoCompany: string;
  LogoMtit: string;
  BackgroundImage: string;
  NameBuildingToScreen: string;
  UpdateBuilding: string;
  UpdateLobby: string;
  VersionFloor: string;
  VersionLobby: string;
  HeightAllCompaniesIntoSivMainInLobby: number;
  ThereIsKiosk: boolean;
  ImageInKiosk: string;
  WithQualityContent: boolean;
  WithAdvertisingContent: boolean;
  TypeProject: string;

  isSelectedImage: boolean;

  constructor() {
    this.Id = null,
      this.NameCompany = '',
      this.NameBuilding = '',
      this.TimeBetweenPages = null,
      this.EveryMillisecondGoToServerInFloor = null;
    this.EveryMillisecondGoToServerInLobby = null;
    this.UrlScreenFloor = '';
    this.UrlScreenLobby = '';
    this.UrlBaseLogoCompany = '';
    this.Phone = '';
    this.Email = '';
    this.Address = '';
    this.Comment = '';
    this.Deleted = false;

    this.TimeBetweenRefreshDisplay = null;
    this.TextSizeFloorNameInLobby = 0;
    this.TextSizeTimeInLobby = 0;
    this.HeightLogoSivInLobby = 0;
    this.HeightSivMainInLobby = 0;

    this.LogoBuilding = '';
    this.LogoCompany = '';
    this.LogoMtit = '';
    this.BackgroundImage = '';
    this.NameBuildingToScreen = '';
    this.UpdateBuilding = '';
    this.UpdateLobby = '';
    this.VersionFloor = '';
    this.VersionLobby = '';
    this.HeightAllCompaniesIntoSivMainInLobby = null;
    this.ThereIsKiosk = false;
    this.ImageInKiosk = '';
    this.WithQualityContent = true;
    this.WithAdvertisingContent = true;
    this.TypeProject = 'building';// office / building / business

    this.isSelectedImage = false;
  }
}
export class SearchBuilding {
  NameBuilding: string;
  WithQualityContent: boolean;
  WithAdvertisingContent: boolean;
  TypeProject: string;
  constructor() {
    this.NameBuilding = '';
    this.WithQualityContent = null;
    this.WithAdvertisingContent = null;
    this.TypeProject = '';// office / building / business
  }
  const(NameBuilding,TypeProject,WithQualityContent,WithAdvertisingContent){
    this.NameBuilding = NameBuilding;
    this.TypeProject = TypeProject;
    this.WithQualityContent = WithQualityContent;
    this.WithAdvertisingContent = WithAdvertisingContent;
  }

}
