
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from "./shared/shared.module";
import { ToastrModule } from 'ngx-toastr';
import { AgmCoreModule } from '@agm/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { StoreModule } from '@ngrx/store';
import { DragulaModule } from 'ng2-dragula';

import { AppComponent } from './app.component';
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";

import { AuthService } from './shared/auth/auth.service';
import { AuthGuard } from './shared/auth/auth-guard.service';

import * as $ from 'jquery';
import { GuardService } from './shared/auth/guard.service';

//import { SignalRModule } from 'ng2-signalr';
///import { SignalRConfiguration } from 'ng2-signalr';
//import { ConnectionResolver } from './shared/routes/connection-resolver';
import { UserGuardService } from './shared/auth/user-guard.service';
// import { TicketGuardService } from './shared/auth/ticket-guard.service';
import { CompanyGuardService } from './shared/auth/company-guard.service';
import { FloorGuardService } from './shared/auth/floor-guard.service';
// import { BuildingComponent } from './building/building.component';
import { BuildingGuardService } from './shared/auth/building-guard.service';
import { NoticeBoardGuardService } from './shared/auth/notice-board-guard.service';
import { ArrowGuardService } from './shared/auth/arrow-guard.service';
import { SideGuardService } from './shared/auth/side-guard.service';
import { TypeScreenGuardService } from './shared/auth/type-screen-guard.service';
import { ImageGuardService } from './shared/auth/image-guard.service';
import { VersionSystemGuardService } from './shared/auth/version-system-guard.service';
import { MessageGuardService } from './shared/auth/message-guard.service';
import { PersonGuardService } from './shared/auth/person-guard.service';
// import { ImageComponent } from './image/image.component';
// import { AddEditImageComponent } from './Image/add-edit-image/add-edit-image.component';
// import { VersionSystemComponent } from './version-system/version-system.component';
// import { AddEditVersionSystemComponent } from './version-system/add-edit-version-system/add-edit-version-system.component';//toUse
// import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
// import { DateAgoPipe } from './shared/pipes/date-ago.pipe';

// import { ArrowComponent } from './arrow/arrow.component';
// import { SideComponent } from './side/side.component';
// import { TypeScreenComponent } from './type-screen/type-screen.component';
// import { AddEditSideComponent } from './side/add-edit-side/add-edit-side.component';
// import { NoticeBoardComponent } from './notice-board/notice-board.component';
//import { FloorComponent } from './floor/floor.component';



export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// export function createConfig(): SignalRConfiguration {
//     const c = new SignalRConfiguration();
//     c.hubName = 'Ng2SignalRHub';
//     c.url = 'http://galtech.mtit.co.il:8080/';
//     // c.url = 'http://localhost:55069/';
//     c.logging = true;

//     // >= v5.0.0
//     c.executeEventsInZone = true; // optional, default is true
//     c.executeErrorsInZone = false; // optional, default is false
//     c.executeStatusChangeInZone = true; // optional, default is true
//     return c;
// }

@NgModule({
    declarations: [
        AppComponent,
        FullLayoutComponent,
        ContentLayoutComponent,
        // ImageComponent,
        // AddEditImageComponent,
        // VersionSystemComponent,
        // AddEditVersionSystemComponent,
        // DateAgoPipe
        // ArrowComponent,
        // SideComponent,
        // TypeScreenComponent,
        // AddEditSideComponent,
        // NoticeBoardComponent,
        // BuildingComponent,
        //FloorComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        StoreModule.forRoot({}),
        AppRoutingModule,
        SharedModule,
        DragulaModule.forRoot(),
        HttpClientModule,
        ToastrModule.forRoot({ positionClass: 'toast-top-center', }),
        NgbModule.forRoot(),
        //SignalRModule.forRoot(createConfig),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyBr5_picK8YJK7fFR2CPzTVMj6GG1TtRGo'
        }),
        // SweetAlert2Module.forRoot(),
    ],
    providers: [
        AuthService,
        AuthGuard,
        GuardService,
        UserGuardService,
        // TicketGuardService,
        CompanyGuardService,
        FloorGuardService,
        BuildingGuardService,
        NoticeBoardGuardService,
        MessageGuardService,
        ArrowGuardService,
        SideGuardService,
        TypeScreenGuardService,
        ImageGuardService,
        VersionSystemGuardService,
        PersonGuardService

        //ConnectionResolver
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
