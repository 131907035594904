import { Injectable } from '@angular/core';
import { VersionSystem } from '../model/version-system-class.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class VersionSystemService {

  constructor(private apiService: ApiService) { }
  GetVersionSystemLatest() {
    return this.apiService.get(this.apiService.rootUrlSystem + 'GetVersionSystemLatest')
}
  getAllVersionSystem() {
      return this.apiService.get(this.apiService.rootUrlSystem + 'GetVersionSystem')
  }
  getVersionSystemById(id: number) {
    return this.apiService.get(this.apiService.rootUrlSystem + 'GetVersionSystem/' + id);
  }
  updateVersionSystem(versionSystem: VersionSystem) {
    return this.apiService.post(this.apiService.rootUrlSystem + 'EditVersionSystem', versionSystem);
  }
  addVersionSystem(versionSystem: VersionSystem) {
    return this.apiService.post(this.apiService.rootUrlSystem + 'AddVersionSystem', versionSystem);
  }
  removeVersionSystem(id: number) {
    return this.apiService.get(this.apiService.rootUrlSystem + 'RemoveVersionSystem/' + id);
  }
}
