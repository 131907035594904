// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  // api:'https://api.view.nuvola.co.il/',//prodNew
  api: 'https://api.view.nuvola.co.il/',//prod
                  
  // api:'http://localhost:55401/',
  //  api:'https://api.home.itfactory.co.il/',//dev
  //api:'https://server.nuvola.co.il:8080/',

  //  apiRss:'https://localhost:44349/api/Rss/'
  apiRss: 'https://rss.view.nuvola.co.il/api/Rss/',

  //  api:'https://api.view.nuvola.co.il/',//prod

  //api:'http://galtech.mtit.co.il:8080/api/TicketSystem',
  // api: 'http://localhost:55069/api/TicketSystem/'
  // api:'https://apiscreen.nuvola.co.il/api/',

  // api:'https://api-dev.nuvola.co.il/api/'
  // api:'http://138.201.60.18/plesk-site-preview/api-dev.nuvola.co.il/api/'//testing
  // api:'https://apiscreen-reserve.nuvola.co.il/api/',
  // companyImagePath:'http://localhost:55401/Resources/companies_img/',
  // imagePath: 'http://localhost:55401/'
};
