import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Company } from '../model/company-class';
import { MainDataService } from './main-data.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  buildingId: number = 0;
  constructor(private apiService: ApiService, private mainData: MainDataService) {
    this.buildingId = mainData.currentBuilding.Id;
  }
  onUploadFile(fd: any) {
    return this.apiService.post('/assets/img/digital-screen/', fd);

  }
  getFloorList(id) {
    return this.apiService.get(this.apiService.rootUrlScreen + 'GetFloors?buildingId=' +id);
  }
updateLocationinLobbyAndFloor(companies: Company[]){
  return this.apiService.post(this.apiService.rootUrlManagementSystem + 'ReplaceLocationInLobbyAndFloor', companies)
}
getScopeList() {
  return this.apiService.get('assets/data/list-scope.json')
}

  getArrowList(id) {
    return this.apiService.get(this.apiService.rootUrlScreen + 'GetArrows?buildingId=' +id);
  }
  getSideList(id) {
    return this.apiService.get(this.apiService.rootUrlScreen + 'GetSides?buildingId=' +id);
  }
  deleteCompany(id) {
    return this.apiService.get(this.apiService.rootUrlManagementSystem + 'RemoveCompany?id=' + id);
  }
  addCompany(company: Company) {
    return this.apiService.post(this.apiService.rootUrlManagementSystem + 'AddCompany', company)
  }
  updateCompany(company: Company) {

    return this.apiService.post(this.apiService.rootUrlManagementSystem + 'EditCompany', company)
  }
  getCompanyList() {

    return this.apiService.get(this.apiService.rootUrlScreen + 'GetCompanies?buildingId=' +this.buildingId);
  }

  getCompanyListById(buildingId) {
    // if (buildingId && buildingId!= -1)
      // buildingId =1;
    return this.apiService.get(this.apiService.rootUrlScreen + 'GetCompanies?buildingId=' +buildingId);
  }

  getCompanyById(id) {
    return this.apiService.get(this.apiService.rootUrlScreen + 'GetCompany/' + id);
  }


  getCompanyList2() {
    return this.apiService.apiCaller('get', '/GetAllCompanies');
  }
  addCompany2(company: Company) {
    return this.apiService.apiCaller('post', '/AddCompany', company);
  }
  getCompanyById2(id) {
    return this.apiService.apiCaller('get', '/GetCompanyById?companyId=' + id);
  }
  updateCompany2(company: Company) {
    return this.apiService.apiCaller('post', '/UpdateCompany', company);
  }
  deleteCompany2(id) {
    return this.apiService.apiCaller('get', '/DeleteCompany?companyId=' + id);
  }
}
