import { Building } from "./building-class.model";

export class Permission {
  Id:number;
  BuildingId:number;
  UserId:number;
  Building:Building;
  // constructor() {
  //     this.Name='';
  //     this.Deleted=false;
  // }
}
