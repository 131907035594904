import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Router, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { User } from '../model/user-class';

@Injectable({
  providedIn: 'root'
})
export class UserGuardService implements CanActivate {


  constructor(private authService: AuthService, private router: Router, private activateroute: ActivatedRoute) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //const currentUser: User = JSON.parse(localStorage.getItem('currentUser'));
  //  this.authService.isAuthenticated().subscribe(data => {
    //   if (data) return true;
    //   else {
    //     this.router.navigate(['/pages/error']);
    //     return false;
    //   }
    // });
    if (this.authService.isAuthenticated()) {
    // && currentUser.IsAllowToCreateUser
     return true;
    }
    this.router.navigate(['/pages/error']);
    return false;
  }
}
