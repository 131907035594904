import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../model/user-class';
import { Building } from '../model/building-class.model';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  uri = '';
  private api = environment.api + 'api/';
  readonly rootUrlManager = this.api + 'Manager/';
  readonly rootUrlAuthentication = this.api + 'Authentication/';
  readonly rootUrlManagementSystem = this.api + 'ManagementSystem/';
  readonly rootUrlScreen = this.api + 'Screen/';
  readonly rootUrlSystem = this.api + 'System/';

  public apiRss = environment.apiRss;


//http://138.201.60.18/plesk-site-preview/api-dev.nuvola.co.il/api/ManagementSystem/AddNoticeBoard
  constructor(private http: HttpClient) { }

  public getJsonOptions() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return httpOptions;
  }

  public setOptions(body: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: body
    };
    return options;
  }

  /**
       * @name extractData
       * @function extracts the data from the response
       * @returns response
       * @param response from the server
       */

  public extractData(res: Response) {
    const body = res;
    return body || {};
  }

  public apiCaller(type: string, url: string, data?: any): any {
    //this.uri = this.api + url;
    if (type === 'get') {
      return this.get(this.uri);
    } else {
      return this.post(this.uri, data);
    }
    //return this.http.get('https://apiscreen.nuvola.co.il/api/screen/GetCompaniesShowInFloorByFloorId/9')
    //return this.http.get('http://localhost:55401/api/screen/GetCompaniesShowInFloorByFloorId/9')
    //return this.post('http://localhost:55401/api/Authentication/login', data)
    //return this.post('https://apiscreen.nuvola.co.il/api/Authentication/login', data)
  }
  public get(url: string): any {
    return this.http.get(url, { headers: this.getHeaders() });
  }
  public post(url: string, data: any): any {
    return this.http.post(url, data, { headers: this.getHeaders() });
  }
  public put(url: string, data: any): any {
    return this.http.put(url, data, { headers: this.getHeaders() });
  }
  public delete(url: string): any {
    return this.http.delete(url, { headers: this.getHeaders() });
  }
  private getHeaders(): HttpHeaders {
    let token = localStorage.getItem('token');
    if(token ==null) token = '';
    //let myHeaders = new Headers({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache','Custom':token });
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache','Custom':token,'Access-Control-Allow-Origin': '*' });
    // headers.append('Access-Control-Allow-Origin', '*');
    //headers.append('Custom',token);
    //headers.append('Content-Type','application/json');

    return headers;
  }
  public postWithHeader(url: string, data: any,header:any): any {
    return this.http.post(url, data, { headers: header });
  }
  public getWithHeader(url: string,header:any): any {
    return this.http.get(url, { headers: header });
  }
  public getWithHeaderMedia(url: string): any {
    let header =  new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache','secret':'QS3x59AfBrT*jEXd','Access-Control-Allow-Origin': '*' })
    return this.http.get(url, { headers: header });
  }


  // getYoutube(url){
  //   // const headerDict = {
  //   //   'Content-Type': 'application/json',
  //   //   'Accept': 'application/json',
  //   //   'Access-Control-Allow-Headers': 'Content-Type',
  //   // }

  //   // const requestOptions = {
  //   //   headers: new Headers(headerDict),
  //   // };

  //   const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json','Access-Control-Allow-Headers': 'Content-Type' });
  //   return this.http.get(url, { headers:headers })
  // }

}
