import { Component, AfterViewChecked, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../auth/auth.service';
import { Subject } from 'rxjs/Subject';
import { GuardService } from '../auth/guard.service';
import { UserService } from '../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { User } from '../model/user-class';
import { Router } from '@angular/router';
import { MainDataService } from '../services/main-data.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements AfterViewChecked {
    currentLang = 'he';
    toggleClass = 'ft-maximize';
    placement = 'bottom-right'
    public isCollapsed = true;
    userModal: User;
    userId: any;
    currentUser:User;
    userRouteLink: any;

    constructor(public translate: TranslateService, private authService: AuthService, private _guard: GuardService,
        private router: Router, private userService: UserService, private toasterService: ToastrService, private mainData: MainDataService) {
        const browserLang: string = translate.getBrowserLang();
        //this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        //this._guard.setCurrentLanguage(browserLang);
        translate.use(browserLang.match(/en|he/) ? browserLang : 'he');
        this.translate.use('he');
        //this.ChangeLanguage('he');
        this.userModal = new User();
        this.currentUser = new User();

        //this.getMyUser();

    }

    ngOnInit() {
        this.getMyUser();
    }
    getMyUser() {
       // this.currentUser = this.mainData.getCurrentUser();
       // this.userRouteLink = "/user/edit-user/" + this.currentUser.Id;

        //this.currentUser =
        this.userService.getMyUser().subscribe(data => {
            if (data) {
                this.currentUser = data as User;
                this.userRouteLink = "/user/edit-user/?id=" + this.currentUser.Id;
                //this.toasterService.success(data.message);
            } else {
                //this.toasterService.error(data.message);
                this.router.navigate(['/pages/login']);
            }
        }
        // ,error => {
        //     this.toasterService.error('Server Error,go to login');
        //     this.router.navigate(['/pages/login']);
        // }
        );

    }
    ngAfterViewChecked() {

        // setTimeout(() => {
        //     var wrapperDiv = document.getElementsByClassName("wrapper")[0];
        //     var dir = wrapperDiv.getAttribute("dir");
        //     if (dir === 'rtl') {
        //         this.placement = 'bottom-left';
        //     }
        //     else if (dir === 'ltr') {
        //         this.placement = 'bottom-right';
        //     }
        // }, 3000);


    }

    ChangeLanguage(language: string) {
        //let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.translate.use(language);
        this._guard.setCurrentLanguage(language);
        //this.userModal.Id = currentUser.UserID;
        // this.userModal.userLanguage = language;
        var currentLang = localStorage.getItem('userLang');

        if (currentLang != language) {
            //this.userService.updateUserLang(this.userModal).subscribe(data => {
                //if (data.status) {
                    localStorage.setItem('userLang', language);
                    //this.toasterService.success(data.message);
                // }
                // else {
                //     this.toasterService.error(data.message);
                // }
            //},
            // error => {
            //     this.toasterService.error('Server Error');
            // }
            //);
        }
    }

    ChangeLanguage2(language: string) {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.translate.use(language);
        this._guard.setCurrentLanguage(language);
        this.userModal.Id = currentUser.UserID;
        // this.userModal.userLanguage = language;
        var currentLang = localStorage.getItem('userLang');

        if (currentLang != language) {
            this.userService.updateUserLang(this.userModal).subscribe(data => {
                if (data.status) {
                    localStorage.setItem('userLang', language);
                    this.toasterService.success(data.message);
                } else {
                    this.toasterService.error(data.message);
                }
            }, error => {
                this.toasterService.error('Server Error');
            });
        }
    }

    ToggleClass() {
        if (this.toggleClass === 'ft-maximize') {
            this.toggleClass = 'ft-minimize';
        }
        else
            this.toggleClass = 'ft-maximize'
    }
    onLogOut() {
        //this.userService.logOutUser(this.currentUser.Id).subscribe(data => {
          //  if (data.status) {
            //    this.toasterService.success(data.message);
                localStorage.removeItem('isLogged');
                localStorage.removeItem('currentUser');
                localStorage.removeItem('userLang');
                localStorage.removeItem('token');
                localStorage.removeItem('BuildingID');
                this.router.navigate(['/pages/login']);
            //} else {
            //    this.toasterService.error(data.message);
            //}
        //}, error => {
         //   this.toasterService.error('Server Error');
        //});
    }
}
