import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Message } from '../model/message-class.model';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  buildingId: any = 0;

  constructor(private apiService: ApiService) { }
  getMessagesToBuilding() {
    this.buildingId = localStorage.getItem("BuildingID");
    if (this.buildingId && this.buildingId != -1)
      return this.apiService.get(this.apiService.rootUrlScreen + 'GetMessages?buildingId=' + this.buildingId)
  }
  getMessageById(id: number) {
    return this.apiService.get(this.apiService.rootUrlScreen + 'GetMessage/' + id);
  }
  updateMessage(message: Message) {
    return this.apiService.post(this.apiService.rootUrlManagementSystem + 'EditMessage', message);
  }
  addMessage(message: Message, buildingId?: number) {
    if (buildingId)
      message.BuildingId = buildingId;
    return this.apiService.post(this.apiService.rootUrlManagementSystem + 'AddMessage', message);
  }
  addMessageToSelected(message1: Message, buildingsId: number[]) {
    var myData = {message: message1,buildingsId:buildingsId};
// myData.message = message1;
// myData.buildingsId = buildingsId;
    return this.apiService.post(this.apiService.rootUrlManagementSystem + 'AddMessageToBuildings' , myData);
  }
  removeMessage(id: number) {
    return this.apiService.get(this.apiService.rootUrlManagementSystem + 'RemoveMessage/' + id);
  }
}
