import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { AuthService } from './auth.service';
import { User } from '../model/user-class';

@Injectable({
  providedIn: 'root'
})
export class CompanyGuardService implements CanActivate {

  constructor(private authService: AuthService, private router: Router, private activateroute: ActivatedRoute) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
   // const currentUser: User = JSON.parse(localStorage.getItem('currentUser'));
    // && currentUser.IsAllowToCreateCompany
    if (this.authService.isAuthenticated()) {
      return true; 
    }
    this.router.navigate(['/pages/error']);
    return false;
  }
}
