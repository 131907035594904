import { RouteInfo } from './sidebar.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [

  {
    path: '/dashboard', name: 'dashboard', title: 'Dashboard', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, isVisible: true, submenu: []
    // badgeClass:'badge badge-pill badge-danger float-right mr-1 mt-1'
    // , submenu: [
    //     { path: '/dashboard', title: 'Dashboard1', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    // //    { path: '/dashboard/dashboard2', title: 'Dashboard2', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    // ]
  },
  {
    path: '', name: 'user', title: 'User.Title', icon: 'ft-users', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, isVisible: true,
    submenu: [
      {
        path: '/user/add-user', name: 'adduser', title: 'User.Add_User', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, isVisible: true, submenu: []
      },
      {
        path: '/user/view-user', name: 'viewuser', title: 'User.View_User', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, isVisible: true, submenu: []
      }
    ]
  },
  // {
  //   path: '', title: 'Person.Title', name: 'person', icon: 'ft-list', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, isVisible: true,
  //   submenu: [
  //     {
  //       path: '/person/add-person', name: 'addperson', title: 'Person.Add_Person', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, isVisible: true, submenu: []
  //     },
  //     {
  //       path: '/person/view-person', name: 'viewperson', title: 'Person.View_Person', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, isVisible: true, submenu: []
  //     }
  //   ]
  // },

  // {
  //     path: '', name: 'ticket', title: 'Ticket.Title', icon: 'ft-clipboard', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, isVisible: true,
  //     submenu: [
  //         {
  //             path: '/ticket/add-ticket', name: 'addticket', title: 'Ticket.Add_Ticket', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, isVisible: true, submenu: []
  //         },
  //         {
  //             path: '/ticket/view-ticket', name: 'viewticket', title: 'Ticket.View_Ticket', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, isVisible: true, submenu: []
  //         }
  //     ]
  // },
  // {
  //     path: '/reports/view-reports', name: 'reports', title: 'Reports.Title', icon: 'ft-edit', class: '', badge: 'חדש', badgeClass: 'badge badge-pill badge-primary float-right mr-1 mt-1', isExternalLink: false, isVisible: true,
  //     submenu: []
  // },
  // {
  //     path: '', name: 'globalsetting', title: 'GlobalSettings.GlobalSettings', icon: 'ft-settings', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, isVisible: false,
  //     submenu: [
  //         {
  //             path: '/global-settings/view-global-settings', name: 'smtpsetting', title: 'GlobalSettings.SMTPTitle', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, isVisible: true, submenu: []
  //         },
  //         {
  //             path: '/global-settings/view-email-templates', name: 'emailtemplate', title: 'GlobalSettings.Email_Template', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, isVisible: true, submenu: []
  //         }
  //     ]
  // },
  {
    path: '/floor/view-floor', title: 'Floor.Title', name: 'floor', icon: 'ft-monitor', class: '', badge: '', badgeClass: '', isExternalLink: false, isVisible: true,
    submenu: [
      // {
      //   path: '/floor/add-floor', name: 'addfloor', title: 'Floor.Add_Floor', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, isVisible: true, submenu: []
      // },
      // {
      //   path: '/floor/view-floor', name: 'viewfloor', title: 'Floor.View_Floor', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, isVisible: true, submenu: []
      // }
    ]
  },
  {
    path: '/company/view-company', title: 'Company.Title', name: 'company', icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, isVisible: true,
    submenu: [
      // {
      //   path: '/company/add-company', name: 'addcompany', title: 'Company.Add_Company', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, isVisible: true, submenu: []
      // },
      // {
      //   path: '/company/view-company', name: 'viewcompany', title: 'Company.View_Company', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, isVisible: true, submenu: []
      // }
    ]
  },
  {
    path: '/notice-board/view-notice-board', title: 'NoticeBoard.Title', name: 'notice-board', icon: 'ft-clipboard', class: '', badge: '', badgeClass: '', isExternalLink: false, isVisible: true,
    submenu: [
      // {
      //   path: '/notice-board/add-notice-board', name: 'addnotice-board', title: 'NoticeBoard.Add_NoticeBoard', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, isVisible: true, submenu: []
      // },
      // {
      //   path: '/notice-board/view-notice-board', name: 'viewnotice-board', title: 'NoticeBoard.View_NoticeBoard', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, isVisible: true, submenu: []
      // }
    ]
  },
  {
    path: '/message/view-message', title: 'Message.Title', name: 'message', icon: 'ft-clipboard', class: '', badge: '', badgeClass: 'badge badge-pill badge-primary float-right mr-1 mt-1', isExternalLink: false, isVisible: true,
    submenu: [
      // {
      //   path: '/message/add-message', name: 'addmessage', title: 'Message.Add_Message', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, isVisible: true, submenu: []
      // },
      // {
      //   path: '/message/view-message', name: 'view', title: 'Message.View_Message', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, isVisible: true, submenu: []
      // }
    ]
  },
  {
    path: '/image/view-image', title: 'Image.Title', name: 'image', icon: 'ft-image', class: '', badge: '', badgeClass: 'badge badge-pill badge-primary float-right mr-1 mt-1', isExternalLink: false, isVisible: true,
    submenu: [
      // {
      //   path: '/image/add-image', name: 'addimage', title: 'Image.Add_Image', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, isVisible: true, submenu: []
      // },
      // {
      //   path: '/image/view-image', name: 'viewimage', title: 'Image.View_Image', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, isVisible: true, submenu: []
      // }
    ]
  },
  {
    path: '', title: 'Building.Title', name: 'building', icon: 'ft-briefcase', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, isVisible: true,
    submenu: [
      // {
      //   path: '/building/add-building', name: 'addbuilding', title: 'Building.Add_Building', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, isVisible: true, submenu: []
      // },
      {
        path: '/building/view-building', name: 'viewbuilding', title: 'Building.View_Building', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, isVisible: true, submenu: []
      },
      // {
      //   path: '/arrow/add-arrow', name: 'addarrow', title: 'Arrow.Add_Arrow', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, isVisible: true, submenu: []
      // },
      {
        path: '/arrow/view-arrow', name: 'viewarrow', title: 'Arrow.View_Arrow', icon: 'ft-arrow-down-left', class: '', badge: '', badgeClass: '', isExternalLink: false, isVisible: true, submenu: []
      },
      // {
      //   path: '/side/add-side', name: 'addside', title: 'Side.Add_Side', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, isVisible: true, submenu: []
      // },
      {
        path: '/side/view-side', name: 'viewside', title: 'Side.View_Side', icon: 'ft-align-center', class: '', badge: '', badgeClass: '', isExternalLink: false, isVisible: true, submenu: []
      },
      // {
      //   path: '/type-screen/add-type-screen', name: 'addtype-screen', title: 'TypeScreen.Add_TypeScreen', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, isVisible: true, submenu: []
      // },
      {
        path: '/type-screen/view-type-screen', name: 'viewtype-screen', title: 'TypeScreen.View_TypeScreen', icon: 'ft-settings', class: '', badge: '', badgeClass: '', isExternalLink: false, isVisible: true, submenu: []
      }
    ]
  },
  // {
  //   path: '', title: 'Arrow.Title', name: 'arrow', icon: 'ft-arrow-down-left', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-primary float-right mr-1 mt-1', isExternalLink: false, isVisible: true,
  //   submenu: [
  //     {
  //       path: '/arrow/add-arrow', name: 'addarrow', title: 'Arrow.Add_Arrow', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, isVisible: true, submenu: []
  //     },
  //     {
  //       path: '/arrow/view-arrow', name: 'viewarrow', title: 'Arrow.View_Arrow', icon: 'ft-arrow-down-left', class: '', badge: '', badgeClass: '', isExternalLink: false, isVisible: true, submenu: []
  //     }
  //   ]
  // },
  // {
  //   path: '', title: 'Side.Title', name: 'side', icon: 'ft-align-center', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-primary float-right mr-1 mt-1', isExternalLink: false, isVisible: true,
  //   submenu: [
  //     {
  //       path: '/side/add-side', name: 'addside', title: 'Side.Add_Side', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, isVisible: true, submenu: []
  //     },
  //     {
  //       path: '/side/view-side', name: 'viewside', title: 'Side.View_Side', icon: 'ft-align-center', class: '', badge: '', badgeClass: '', isExternalLink: false, isVisible: true, submenu: []
  //     }
  //   ]
  // },
  // {
  //   path: '', title: 'TypeScreen.Title', name: 'type-screen', icon: 'ft-settings', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-primary float-right mr-1 mt-1', isExternalLink: false, isVisible: true,
  //   submenu: [
  //     {
  //       path: '/type-screen/add-type-screen', name: 'addtype-screen', title: 'TypeScreen.Add_TypeScreen', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, isVisible: true, submenu: []
  //     },
  //     {
  //       path: '/type-screen/view-type-screen', name: 'viewtype-screen', title: 'TypeScreen.View_TypeScreen', icon: 'ft-settings', class: '', badge: '', badgeClass: '', isExternalLink: false, isVisible: true, submenu: []
  //     }
  //   ]
  // },
  {
    path: '', title: 'VersionSystem.Title', name: 'version-system', icon: 'ft-info', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-primary float-right mr-1 mt-1', isExternalLink: false, isVisible: true,
    submenu: [
      {
        path: '/version-system/add-version-system', name: 'addversion-system', title: 'VersionSystem.Add_VersionSystem', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, isVisible: true, submenu: []
      },
      {
        path: '/version-system/view-version-system', name: 'viewversion-system', title: 'VersionSystem.View_VersionSystem', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, isVisible: true, submenu: []
      }
    ]
  },
  // {
  //     path: '', title: 'Forms', icon: 'ft-edit', class: 'has-sub', badge: 'New', badgeClass: 'badge badge-pill badge-primary float-right mr-1 mt-1', isExternalLink: false,
  //     submenu: [
  //         {
  //             path: '', title: 'Elements', icon: '', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
  //             submenu: [
  //                 { path: '/forms/inputs', title: 'Inputs', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //                 //   { path: '/forms/input-groups', title: 'Input Group', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //                 //   { path: '/forms/input-grid', title: 'Input Grid', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
  //             ]
  //         },
  //         // {
  //         //     path: '', title: 'Layouts', icon: '', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
  //         //     submenu: [
  //         //         { path: '/forms/basic', title: 'Basic Forms', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //         //         { path: '/forms/horizontal', title: 'Horizontal Forms', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //         //         { path: '/forms/hidden-labels', title: 'Hidden Labels', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //         //         { path: '/forms/form-actions', title: 'Form Actions', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //         //         { path: '/forms/bordered', title: 'Bordered Forms', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //         //         { path: '/forms/striped-rows', title: 'Striped Rows', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
  //         //     ]
  //         // },
  //         // { path: '/forms/validation', title: 'Validation', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //         // { path: '/forms/wizard', title: 'Wizard', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //         // { path: '/forms/ngx', title: 'NGX Wizard', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //         // { path: '/forms/archwizard', title: 'ArchWizard', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
  //     ]
  // },
  // {
  //     path: '', title: 'Tables', icon: 'ft-grid', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
  //     submenu: [
  //         { path: '/tables/smart', title: 'Smart Tables', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

  //     ]
  // },
  // {
  //     path: '', title: 'Pages', icon: 'ft-copy', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
  //     submenu: [
  //         // { path: '/pages/forgotpassword', title: 'Forgot Password', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //         // { path: '/pages/login', title: 'Login', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //         // { path: '/pages/register', title: 'Register', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //        // { path: '/pages/profile', title: 'User Profile', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //         // { path: '/pages/lockscreen', title: 'Lock Screen', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //         // { path: '/pages/invoice', title: 'Invoice', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //         // { path: '/pages/error', title: 'Error', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //         // { path: '/pages/search', title: 'Search', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //     ]
  // },
];
