import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { LoginModal } from '../model/login-class';
import { User } from '../model/user-class';
import { Observable } from 'rxjs';
import 'rxjs/Rx';
import { GuardService } from './guard.service';
import { ApiService } from '../services/api.service';
import { UserService } from '../services/user.service';
import { elementDef } from '@angular/core/src/view';

@Injectable()
export class AuthService {
  token: string;

  constructor(private router: Router, private guard: GuardService, private api: ApiService, private userService: UserService) { }

  signupUser(email: string, password: string) {
    //your code for signing up the new user
  }

  signinUser(username: string, password: string) {
    let loginModalReq = new LoginModal();
    loginModalReq.UserName = username;
    loginModalReq.Password = password;
    return this.onLogin(loginModalReq)
      .map((result) => {
        // if (!result.status) {
        //   console.log('Expected a User, received => ', users);
        // }
        if (result) {
          const min = 80;
          const expireTime = (1 * min) / 24;
          localStorage.setItem('isLogged', 'true');
          localStorage.setItem('token', result);
          //localStorage.setItem('currentUser', JSON.stringify(result.data));
          localStorage.setItem('userLang', 'he');
          this.guard.setCurrentUser(result);
        }
        return result;
      }).catch((error: any) =>

        Observable.throw(error || 'Server error-catch')
      );

    // this.router.navigate(['/dashboard']);
    //your code for checking credentials and getting tokens for for signing in user

  }

  getToken() {
    return this.token;
  }

  isAuthenticated() {
    // let loggedIn = localStorage.getItem('isLogged');
    // let user = localStorage.getItem('currentUser');
    // let currentUser = this.guard.getCurrentUser();

    let token = localStorage.getItem('token');
    if (token) {
      //this.userService.getMyUser();
      return true;
      //.subscribe(data => {
        //if (data) {
          //this.toasterService.success(data.message);
        //  return true;
        //}
        //else return false;
      //})
    }
    else return false;
  }
  // this.userService.getMyBuildings().subscribe(dataBuilding => {
  //   if (dataBuilding) {
  //     //work good
  //     return true;
  //   }
  //else {
  //no building
  //this.toasterService.warning('בקש מהמנהל לקשר אותך לבניין','התחברות הצליחה');
  //this.router.navigate(['/pages/login']);
  //this.router.navigate(['/company/edit-company/']);
  //}
  //}, error => {
  //this.toasterService.error('Server Error in Building,go to login');
  //this.router.navigate(['/pages/login']);
  //}
  //)
  // })
  //}
  //else {
  //no user
  //this.toasterService.error(data.message);
  //this.router.navigate(['/pages/login']);
  // }
  //}
  // , error => {
  //   //this.toasterService.error('Server Error,go to login');
  //   this.router.navigate(['/pages/login']);
  // }
  //)
  //}
  //return false;
  // if (user.UserName === currentUser.UserName) {
  //   return true;
  // } else {
  //   return false;
  // }
  //return loggedIn;
  // here you can check if user is authenticated or not through his token
  // return true;
  //}
  onLogin(data: any): Observable<any> {
    // return;
    return this.api.post(this.api.rootUrlAuthentication + 'login', data);
  }
}
