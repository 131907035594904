import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { User } from '../model/user-class';

@Injectable({
  providedIn: 'root'
})
export class GuardService {

  private _currentUser: User;


  currentLanguage = new Subject();
  private currentUser = new BehaviorSubject<User>(new User());
  user$ = this.currentUser.asObservable();



  constructor(private http: HttpClient) { }

  setCurrentUser(user: User) {
    if (user) {
      this._currentUser = user;
      this.currentUser.next(user);
      this.setCurrentLanguage('he');
    }
  }

  // logoutCurrentUser() {
  //   this.currentUser.next(new UserLogin());
  // }

  getCurrentUser(): any {
    return this._currentUser;
  }
  setCurrentLanguage(language: any) {
    if (language) {
      this.currentLanguage.next(language);
    }

  }
}
