import { Component, OnInit } from '@angular/core';
import { ROUTES } from './sidebar-routes.config';
import { RouteInfo } from "./sidebar.metadata";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../services/user.service';
import { MainDataService } from '../services/main-data.service';

declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})

export class SidebarComponent implements OnInit {
  public menuItems: RouteInfo[];
  public currentUser: any;

  constructor(private router: Router,
    private route: ActivatedRoute, public translate: TranslateService, public userService: UserService, private mainData: MainDataService) {
    //this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    // this.currentUser.IsManager = true;
    //this.currentUser = this.userService.currentUser;
    this.currentUser = this.mainData.currentUser;
  }

  ngOnInit() {
    $.getScript('./assets/js/app-sidebar.js');
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.menuItems.forEach(element => {
      // if(true)
      // {

      if (element.name == 'user') {

        element.isVisible = this.mainData.currentUser.IsManager;
      }
      if (element.name == 'side' || element.name == 'arrow' ||
       element.name == 'type-screen' || element.name == 'image' ||
       element.name == 'version-system' || element.name == 'building') {
        element.isVisible = this.mainData.currentUser.IsManager;
      }
      if (element.name == 'person') {
        element.isVisible = this.mainData.currentUser.IsAllowToViewPerson;
      }
      if (element.name == 'company') {
        element.isVisible = this.mainData.currentUser.IsAllowToViewCompany;
      }
      if (element.name == 'image') {
        element.isVisible = this.mainData.currentUser.IsAllowToViewImage;
      }
      if (element.name == 'message') {
        element.isVisible = this.mainData.currentUser.IsAllowToViewMessage;
      }
      if (element.name == 'notice-board') {
        element.isVisible = this.mainData.currentUser.IsAllowToViewNoticeBoard;
      }
      if (element.name == 'floor') {
        element.isVisible = this.mainData.currentUser.IsAllowToViewFloor;
      }

      element.submenu.forEach(subElem => {

        // if(subElem.name == 'addcompany') {
        //     subElem.isVisible = true;//this.currentUser.IsAllowToCreateCompany;
        // }
        // if(subElem.name == 'adduser') {
        //     subElem.isVisible = true;//this.currentUser.IsAllowToCreateUser;
        // }
        // if(subElem.name == 'addticket') {
        //     subElem.isVisible = false;// this.currentUser.IsAllowToCreateTicket;
        // }
        // if(subElem.name == 'reports') {
        //     subElem.isVisible = false; //this.currentUser.IsAllowToCreateUser;
        // }
      });
      // }
      // else if(false) {
      //     if(element.name == "globalsetting")
      //         element.isVisible = true;
      // }
    });
  }

  //NGX Wizard - skip url change
  ngxWizardFunction(path: string) {
    if (path.indexOf('forms/ngx') !== -1)
      this.router.navigate(['forms/ngx/wizard'], { skipLocationChange: false });
  }
}
