import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Tickets, TicketComments } from '../model/ticket-class';

@Injectable({
  providedIn: 'root'
})
export class TicketService {


  constructor(private apiService: ApiService) { }

  getTicketStatusList() {
    return this.apiService.apiCaller('get', '/GetAllTicketStatus');
  }

  getTicketList() {
    return this.apiService.apiCaller('get', '/GetAllTickets');
  }
  addTicket(ticket: Tickets) {
    return this.apiService.apiCaller('post', '/AddTicket', ticket);
  }
  getTicketById(id) {
    return this.apiService.apiCaller('get', '/GetTicketById?ticketId=' + id);
  }
  update(ticket: Tickets) {
    return this.apiService.apiCaller('post', '/UpdateTicket', ticket);
  }
  deleteTicket(id) {
    return this.apiService.apiCaller('get', '/DeleteTicket?ticketId=' + id);
  }
  updateTicketStatusToSolved(ticket: Tickets) {
    return this.apiService.apiCaller('post', '/UpdateTicketStatusToSolved', ticket);
  }
  addComment(comment: TicketComments) {
    return this.apiService.apiCaller('post', '/AddCommentOnTicket', comment);
  }
  getCommentTicketById(id) {
    return this.apiService.apiCaller('get', '/GetAllCommentsByTicketId?ticketId=' + id);
  }
}
