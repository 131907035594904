import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { NavbarComponent } from "./navbar/navbar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { CustomizerComponent } from './customizer/customizer.component';
import { NotificationSidebarComponent } from './notification-sidebar/notification-sidebar.component';
import { ToggleFullscreenDirective } from "./directives/toggle-fullscreen.directive";
import { ConfirmDialogComponent } from './component/confirm-dialog/confirm-dialog.component';
import { GuardService } from './auth/guard.service';
import { ApiService } from './services/api.service';
import { AuthService } from './auth/auth.service';
import { CompanyService } from './services/company.service';
import { TicketService } from './services/ticket.service';
import { UserService } from './services/user.service';
import { DashboardService } from './services/dashboard.service';
//import { MessageGuardComponent } from './services/message-guard.component';
import { MessageService } from './services/message.service';
// import { DateAgoPipe } from './pipes/date-ago.pipe';


@NgModule({
    exports: [
        CommonModule,
        NavbarComponent,
        SidebarComponent,
        CustomizerComponent,
        ConfirmDialogComponent,
        NotificationSidebarComponent,
        ToggleFullscreenDirective,
        NgbModule,
        TranslateModule
    ],
    imports: [
        RouterModule,
        CommonModule,
        NgbModule,
        TranslateModule

    ],
    declarations: [
        NavbarComponent,
        SidebarComponent,
        CustomizerComponent,
        NotificationSidebarComponent,
        ConfirmDialogComponent,
        ToggleFullscreenDirective,
        //MessageGuardComponent,
        // DateAgoPipe
    ],
    providers: [ApiService, AuthService, CompanyService, TicketService, UserService, DashboardService, MessageService],
    entryComponents: [ConfirmDialogComponent]
})
export class SharedModule { }
