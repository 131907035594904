import { Component, OnInit, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GuardService } from 'app/shared/auth/guard.service';
import { Subscription } from 'rxjs';
import { UserService } from 'app/shared/services/user.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MainDataService } from 'app/shared/services/main-data.service';
import { VersionSystemService } from 'app/shared/services/version-system.service';
import { VersionSystem } from 'app/shared/model/version-system-class.model';
// import swal from 'sweetalert2';
import Swal from 'sweetalert2'


var fireRefreshEventOnWindow = function () {
    var evt = document.createEvent("HTMLEvents");
    evt.initEvent('resize', true, false);
    window.dispatchEvent(evt);
};

@Component({
    selector: 'app-full-layout',
    templateUrl: './full-layout.component.html',
    styleUrls: ['./full-layout.component.scss'],
    providers: []
})

export class FullLayoutComponent implements OnInit {
    browserLang;
    language: Subscription;
    options = {
        direction: 'ltr'
    };


    constructor(public translate: TranslateService, private elementRef: ElementRef, private _guard: GuardService, private userService: UserService, private versionSystemService: VersionSystemService
        , private router: Router, private toasterService: ToastrService, public mainData: MainDataService) {
        this.browserLang = localStorage.getItem('userLang');
        // this.browserLang = 'he';
        this.setPosition();
        this.language = this._guard.currentLanguage.subscribe(data => {
            this.browserLang = data;
            this.setPosition();

        });
        this.getMyUserAndBuildings();
    }

    ngOnInit() {
        //sidebar toggle event listner
        //this.getMyUser();
        // this.elementRef.nativeElement.querySelector('#sidebarToggle')
        //     .addEventListener('click', this.onClick.bind(this));
        //customizer events
        // this.elementRef.nativeElement.querySelector('#cz-compact-menu')
        //     .addEventListener('click', this.onClick.bind(this));
        // this.elementRef.nativeElement.querySelector('#cz-sidebar-width')
        //     .addEventListener('click', this.onClick.bind(this));
    }

    getMyUserAndBuildings() {
        // this.userService.getMyUser().subscribe(data => {
        //     if (data) {
        //         this.toasterService.success(data.message);
        //     }
        //     else {
        //         //no user
        //         this.toasterService.error(data.message);
        //         this.router.navigate(['/pages/login']);
        //     }
        // }, error => {
        //     this.toasterService.error('Server Error,go to login');
        //     this.router.navigate(['/pages/login']);
        // })
        this.userService.getMyBuildings().subscribe(dataBuilding => {
            if (dataBuilding) {
                //work good
            }
            else {
                //no building
                this.toasterService.warning('בקש מהמנהל לשייך אותך לבניין');
                this.router.navigate(['/pages/login']);
                //this.router.navigate(['/company/edit-company/']);
            }
        }, error => {
            this.toasterService.error('אנא התחבר על מנת להמשיך');
            this.router.navigate(['/pages/login']);
        })
        this.versionSystemService.GetVersionSystemLatest().subscribe(data => {
            if (data) {
                var versionLatest = data as VersionSystem;
                var versionId = localStorage.getItem("versionId");
                var GetVersionLatest = localStorage.getItem("GetVersionLatest");

                if (versionId) {
                    if (versionId != versionLatest.Id.toString()) {
                        if (GetVersionLatest == "true") {
                            localStorage.setItem("versionId", versionLatest.Id.toString());
                            localStorage.removeItem("GetVersionLatest")
                            if (versionLatest.SendMessageToUser) {
                                var text = "...קצת על השדרוג";
                                text += "<br/>"
                                var description: string
                                if (!versionLatest.MessageDefault) {
                                    description = versionLatest.DescriptionVersion.split('@@').join('<br/>');
                                }
                                else {
                                    description = ",שיפרנו את הביצועים של המערכת, הוספנו תכונות למסכים";
                                    description += "<br/>"
                                    description += "...טיפלנו בשגיאות ועוד "
                                }
                                text += description;
                                Swal.fire(
                                    '!התחדשנו',
                                    text,
                                    'success'
                                );
                            }
                        }
                        else {
                            // localStorage.removeItem("versionId")
                            localStorage.setItem("GetVersionLatest", "true");
                            var text = ",אנו מעדכנים ומחדשים את המערכת";
                            text += "<br/>"
                            text += "..זה יקח כמה שניות";
                            Swal.fire(
                                '!התחדשנו',
                                text,
                                'success'
                            ).then((result) => {
                                if (result.value) {
                                    window.location.reload(true);
                                }
                            });
                        }
                    }
                }
                else {
                    localStorage.setItem("versionId", versionLatest.Id.toString());
                }
                //work good JSON.parse

            }
            else {
                //no building
                // this.toasterService.warning('בקש מהמנהל לשייך אותך לבניין');
                // this.router.navigate(['/pages/login']);
                //this.router.navigate(['/company/edit-company/']);
            }
        }, error => {
            // this.toasterService.error('אנא התחבר על מנת להמשיך');
            // this.router.navigate(['/pages/login']);
        })
    }
    setPosition() {
        if (this.browserLang === 'en') {
            this.options.direction = 'ltr';
        } else if (this.browserLang === 'he') {
            this.options.direction = 'rtl';
        }
    }

    onClick(event) {
        //initialize window resizer event on sidebar toggle click event
        setTimeout(() => { fireRefreshEventOnWindow() }, 300);
    }

    getOptions($event): void {
        this.options = $event;
    }

}
