import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private apiService: ApiService) { }

  getAdminHeaderData(id, roleId, companyId) {
    return this.apiService.apiCaller('get', '/GetDashBoardDataForUserRole?userId=' + id + '&roleId=' + roleId + '&companyId=' + companyId);
  }
  getLineAreaChartData(id, roleId) {
    return this.apiService.apiCaller('get', '/getLineAreaChartDataForUserRole?userId=' + id + '&roleId=' + roleId);
  }
  getDoughnutChartData(id, roleId) {
    return this.apiService.apiCaller('get', '/GetTotalTicketsByCompany');
  }
  getTicketStatusOfTodayAndLastForteenDays(id, roleId) {
    return this.apiService.apiCaller('get', '/GetTicketStatusOfTodayAndLastForteenDays?userId=' + id + '&roleId=' + roleId);
  }
  getTicketsOpenedByTech(id, roleId) {
    return this.apiService.apiCaller('get', '/GetTicketsOpenedByTech?userId=' + id + '&roleId=' + roleId);
  }

  getTicketStatusByTimeFrame(id, roleId) {
    return this.apiService.apiCaller('get', '/GetTicketStatusByTimeFrame?userId=' + id + '&roleId=' + roleId);
  }
}