export class User {
    Id: any;
    // UserRoleID: any;//
    //RoleName: any;
    //CompanyId: any;
    Email: any;
    //UserName: any;
    FirstName: any;
    LastName: any;
    Password: any;
    Phone: any;
    //ExtensionNo: any;
    // userLanguage: any;//
    IsActive: Boolean;
    IsManager: boolean;
    UserName: any;
    //CreatedBy: any;
    //CreatedDate: any;
    //ModifiedBy: any;
    //ModefiedDate: any;
    IsAllowToViewCompany:boolean;//
    IsAllowToViewImage:boolean;//
    IsAllowToViewMessage:boolean;//
    IsAllowToViewNoticeBoard:boolean;//
    IsAllowToViewPerson:boolean;//
    IsAllowToViewFloor:boolean;
    Note:any;
    DisplayName:any;
    AllowedIPs: any;
    constructor() {
        this.Id = null;
        this.IsActive=true;
        this.IsManager=false;
        this.FirstName = '';
        this.LastName = '';
        //this.UserRoleID = '';
        //this.CompanyId = '';
        this.IsAllowToViewNoticeBoard = false;
        this.IsAllowToViewCompany=false;
        this.IsAllowToViewImage = false;
        this.IsAllowToViewMessage = false;
        this.IsAllowToViewPerson = false;
        this.IsAllowToViewFloor = false;
        this.Note = '';
        this.DisplayName = '';
        this.AllowedIPs = '';
    }
}

// export class UserRoles {
//     UserRoleID: any;
//     RoleName: any;

//     constructor() {
//         this.UserRoleID = '';
//         this.RoleName = '';
//     }
// }

export class DropDownModel {
    id: any;
    name: any;

    constructor() {
        this.id = '';
        this.name = '';
    }
}
export class Register {
    UserName:string;
    Password:string;
    FirstName:string;
    LastName:string;
    Email:string;
    IsManager:boolean;
    Phone:string;
    AllowedIPs: any;
    constructor() {
        this.UserName = "";
        this.Password = "";
        this.FirstName = "";
        this.LastName = "";
        this.Email = "";
        this.IsManager = false;
        this.Phone= "";
        this.AllowedIPs="";

    }
}
