import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { User, Register } from '../model/user-class';
import { ChangePasswordModal } from '../model/login-class';
import { tap } from "rxjs/operators";
import { MainDataService } from './main-data.service';
import { Building } from '../model/building-class.model';
import { Permission } from '../model/permissions-class.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  currentUser: User;
  constructor(private apiService: ApiService, private mainData: MainDataService) { }

  getMyBuildings() {
    return this.apiService.get(this.apiService.rootUrlManager + 'GetBuildings')
      .pipe(
        tap(res => {
          this.mainData.setMyBuildings(res as Building[]);
          if (localStorage.getItem("BuildingID")) {
            this.mainData.setCurrentBuildingById(localStorage.getItem("BuildingID"));
          }
          else {
            this.mainData.setCurrentBuilding(res[0] as Building)
            var buildingID = this.mainData.currentBuilding.Id;
            localStorage.setItem("BuildingID", buildingID.toString())
          }
        }
        )
      );
  }
  GetBuildingsByUser(userId) {
    return this.apiService.get(this.apiService.rootUrlManager + 'GetBuildingsByUser/?id='+userId)
  }
  GetPermissionsBuildingsByUser(userId) {
    return this.apiService.get(this.apiService.rootUrlManager + 'GetPermissionsBuildingsByUser/?id='+userId)
  }

  getMyUser() {
    return this.apiService.get(this.apiService.rootUrlManager + 'GetUser')
      .pipe(
        tap(res => {
          this.mainData.setCurrentUser(res as User);
        }
        )
      );
  }
  deleteUser(id: number) {
    return this.apiService.get(this.apiService.rootUrlManager + "RemoveUser/" + id);
  }
  addOrEditUser(user: User) {
    if (user.Id) {
      return this.editUser(user);
    } else {
      return this.addUser(user);
    }
  }
  editUser(user: User) {
    return this.apiService.post(this.apiService.rootUrlManager + "EditUser", user);
  }
  ChangeUserName(userId, userName) {
    return this.apiService.get(this.apiService.rootUrlAuthentication +
      'ChangeUserName?userId=' + userId + '&userName=' + userName)
  }
  ChangePassword(userPass: ChangePasswordModal) {
    userPass.UserId = this.mainData.currentUser.Id;
    var pass = {
      Password: userPass.OldPassword,
      PasswordNew: userPass.NewPassword
    }
    return this.apiService.post(this.apiService.rootUrlAuthentication +
      'ChangePassword?userId=' + userPass.UserId, pass)
  }
  addUser(user: User) {
    var register = new Register();
    register.Email = user.Email;
    register.FirstName = user.FirstName;
    register.IsManager = user.IsManager;
    register.LastName = user.LastName;
    register.Password = user.Password;
    register.Phone = user.Phone;
    register.UserName = user.UserName;
    register.AllowedIPs = user.AllowedIPs;
    return this.apiService.post(this.apiService.rootUrlAuthentication + 'Register', register);
  }
  getUserById(id) {
    return this.apiService.get(this.apiService.rootUrlManager + 'GetUser/' + id);
  }
  getUserList() {
    return this.apiService.get(this.apiService.rootUrlManager + 'GetAllUsers');
  }
  AddPermission(userId: number, buildingId: number) {
    var permission = new Permission();
    permission.BuildingId = buildingId;
    permission.UserId = userId;
    return this.apiService.post(this.apiService.rootUrlManager + 'AddPermissions', permission);
  }
  RemovePermissions(id){
    return this.apiService.get(this.apiService.rootUrlManager + 'RemovePermissions/' + id);
  }
  getUserList2() {
    return this.apiService.apiCaller('get', '/GetAllUsers');
  }
  addUser2(user: User) {
    return this.apiService.apiCaller('post', '/AddUser', user);
  }
  getUserById2(id) {
    return this.apiService.apiCaller('get', '/GetUserByEmailOrId?userId=' + id + '&emailId=');
  }
  update2(user: User) {
    return this.apiService.apiCaller('post', '/UpdateUser', user);
  }
  deleteUser2(id) {
    return this.apiService.apiCaller('get', '/DeleteUser?userId=' + id);
  }
  getUsersRoleList() {
    return this.apiService.apiCaller('get', '/GetAllUserRoles');
  }

  updateUserLang(user: User) {
    return this.apiService.apiCaller('post', '/UpdateUserLanguage', user);
  }

  changeUserPassword(userPass: ChangePasswordModal) {
    return this.apiService.apiCaller('post', '/ChangeUserPassword', userPass);
  }
  logOutUser(userId) {
    return this.apiService.apiCaller('get', '/LogoutUser?userId=' + userId);
  }

}
