import { Injectable } from '@angular/core';
import { User } from '../model/user-class';
import { Building } from '../model/building-class.model';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MainDataService {

  public currentUser: User;
  public currentBuilding: Building;
  public myBuildings: Building[];
  public api = environment.api;
  public currentImageType: number;

  constructor() {
    this.currentUser = new User();
    this.currentBuilding = new Building();
    this.myBuildings = [];
    this.currentImageType = 0;
    //this.currentUser.IsManager=false;
  }

  getCurrentUser() {
    return this.currentUser;
  }
  getCurrentBuilding() {
    return this.currentBuilding;
  }
  getMyBuildings() {
    return this.myBuildings;
  }
  getCurrentImageType() {
    return this.currentImageType;
  }
  setCurrentUser(currentUser: User) {
    this.currentUser = currentUser;
  }
  setCurrentBuilding(currentBuilding: Building) {
    this.currentBuilding = currentBuilding;
  }
  setCurrentBuildingById(currentBuildingId: any) {
    this.myBuildings.forEach(element => {
      if (element.Id == currentBuildingId)
        this.currentBuilding = element;
    })
    this.myBuildings
    // this.currentBuilding = this.myBuildings.filter(res => {
    //   return res.Id == currentBuildingId;}

  }
  setMyBuildings(myBuildings: Building[]) {
    this.myBuildings = myBuildings;
  }
  refreshMyBuildings_isSelectedImage() {
    this.myBuildings.forEach(element => {
      element.isSelectedImage = false;
    });
  }
}
