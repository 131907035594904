export class LoginModal {
    UserName: any;
    Password: any;
    constructor() {
        this.Password = '';
        this.UserName = '';
    }
}

export class ChangePasswordModal {
    UserId: any;
    OldPassword: any;
    NewPassword: any;
    IsAfterLogin: any;
    constructor() {
        this.UserId = 0;
        this.OldPassword = '';
        this.NewPassword = '';
        this.IsAfterLogin = true;
    }
}